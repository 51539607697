<template>
  <div class="search-box">
    <div class="title">查詢條件</div>
    <div class="container">
      <a-radio-group
        v-model:value="searchData.searchItem"
        :options="searchOptions"
      />
      <br />
      <a-input
        v-model:value="searchData.searchValue"
        placeholder=""
        style="width: 300px; margin-top: 10px"
      />
    </div>
    <a-button type="primary" @click="handleSearch">查詢</a-button>
  </div>
</template>

<script>
  import { reactive } from 'vue'
  export default {
    props: ['filter'],
    setup(props, { emit }) {
      const searchData = reactive(props.filter)
      const searchOptions = [
        { label: '手機號碼', value: 'phone' },
        { label: '會員名稱', value: 'name' },
        { label: '會員卡號', value: 'cardID' },
      ]

      const handleSearch = () => {
        emit('search', searchData)
      }
      return {
        searchOptions,
        searchData,
        handleSearch,
      }
    },
  }
</script>

<style lang="less" scoped>
  .search-box {
    .container {
      margin-left: 60px;
    }
    background-color: #fff;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    padding: 20px;

    .ant-collapse-content {
      background-color: #fff;
    }

    button {
      margin: 10px;
    }
  }
</style>
