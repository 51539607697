<template>
  <div class="custDataTable-component">
    <a-pagination
      simple
      v-model:current="tableSetting.currentPage"
      v-model:pageSize="tableSetting.pageSize"
      :total="data.length"
    />
    <a-table
      :pagination="false"
      :bordered="true"
      :columns="tableColumns"
      :data-source="data"
      rowKey="memberId"
      :scroll="{ x: 600, y: 1200 }"
    >
      <template #action="{ text }">
        <a-list size="small">
          <a-list-item v-if="text.action1">
            <a @click="action1(text.memberId)">
              {{ text.action1 }}
            </a>
          </a-list-item>
          <a-list-item v-if="text.action2">
            <a @click="action2(text.memberId)">
              {{ text.action2 }}
            </a>
          </a-list-item>
          <a-list-item v-if="text.action3">
            <a @click="action3(text.memberId)">
              {{ text.action3 }}
            </a>
          </a-list-item>
          <a-list-item v-if="text.action4">
            <a @click="action4(text.memberId)">
              {{ text.action4 }}
            </a>
          </a-list-item>
        </a-list>
      </template>
    </a-table>
  </div>
</template>

<script>
  // TODO 所有查詢table使用同一component
  import { reactive, computed } from 'vue'
  export default {
    props: ['searchResult'],
    setup(props, { emit }) {
      const data = computed(() => props.searchResult)
      const tableSetting = reactive({ currentPage: 1, pageSize: 20 })
      const tableColumns = [
        {
          title: '會員編號',
          width: 120,
          dataIndex: 'memberId',
          key: 'memberId',
          fixed: 'left',
        },
        {
          title: '會員卡號',
          width: 130,
          dataIndex: 'cardId',
          fixed: 'left',
        },
        { title: '會員姓名', width: 100, dataIndex: 'name', fixed: 'left' },
        { title: '會員等級', width: 150, dataIndex: 'levelName' },
        { title: '手機號碼', width: 150, dataIndex: 'phoneNumber' },
        { title: '性別', width: 150, dataIndex: 'gender' },
        { title: '生日', width: 150, dataIndex: 'birthday' },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'action' },
        },
      ]

      const action1 = (value) => {
        emit('action1', value)
      }
      const action2 = (value) => {
        emit('action2', value)
      }
      const action3 = (value) => {
        emit('action3', value)
      }
      const action4 = (value) => {
        emit('action4', value)
      }
      return {
        tableColumns,
        tableSetting,
        data,
        action1,
        action2,
        action3,
        action4,
      }
    },
  }
</script>

<style lang="less">
  .custDataTable-component {
    .ant-pagination {
      text-align: right;
    }

    .ant-table-thead > tr {
      th {
        text-align: center;
      }
    }
    .ant-table-tbody > tr {
      td {
        padding: 3px 10px;
      }
    }
  }
</style>
